.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(3600deg);
  }
}