.campaign-box {
  padding-top: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  overflow: hidden !important;
}

.campaign-box.dark .campaign-box-header {
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.campaign-box .campaign-box-header {
  background: rgba(255, 255, 255, 0.75);
  padding: 0px 8px;
  color: #000000;
}



.map-select-entry {
  background-color: transparent;
  color: inherit;
  width: 100%;
  padding: 2px 4px;
  cursor: pointer;
}

.map-select-entry.selected {
  background-color: rgba(255, 255, 255, 0.11);
  color: rgba(255, 95, 95, 1);
}

.submission-link {
  color: #2383b6;
  text-decoration: none;
}

.submission-player-name {
  white-space: nowrap;
}

.campaign-box.dark .submission-link {
  color: #4dafe4;
}


.campaign-box .difficulty-bar {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  overflow: hidden;
  line-height: 18px;
  font-size: 16px;
  cursor: default;
}

.campaign-box.dark .difficulty-bar {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
}