.campaign-view-box {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.campaign-view-box.relative-size {
  width: unset;
}

.campaign-view-box.name {
  height: 70px;
  background-color: black;
  color: white;
  justify-content: space-around;
  border: 1px solid white;
  border-bottom-width: 5px;
}

.campaign-view-box.map {
  background-color: black;
  border: 1px solid white;
  width: 250px;
  padding: 5px;
  justify-content: space-around;
}

.campaign-view-box.challenge {
  width: 150px;
  border: 1px solid white;
}

.campaign-view-box.submission {
  color: rgb(25, 118, 210);
  border: 1px solid gray;
  padding: 5px;
  font-weight: bold;
  font-size: 22px;
  justify-content: space-around;
}